import React, { useState, useContext, useRef,useEffect } from "react";

import {AppContext} from '../application/provider';
// import imgClosed from '../assets/img/closed-shop.svg'
import imgConfirm from '../assets/img/confirm.svg'
import '../assets/css/operation.css';
import TypeService from '../services/type.service';
//import socketIOClient from "socket.io-client";
import { useHistory } from "react-router-dom";
import OperationService from '../services/operation.service';
// import Referido from '../views/UserRefer1';
import Contador from '../components/countDown/contador';
import CountCircle from '../components/countDown/countCircle';
import CountTimer from '../components/countDown/countTimer';
import ClientSocket from '../components/socket/ClientSocket';
import Loading from '../components/helpers/Loading';
import { HiOutlineClipboardCopy } from 'react-icons/hi';
import  Convertidor from '../components/home/tipoCambio2';
import '../assets/css/contenedorCambio.css'
import Flemayor from '../assets/img/flemayor.svg'
// import Menu from '../views/Form1'
import validate from '../components/helpers/validateValues'
import '../assets/css/operation.css';
import peru from '../assets/img/peru.svg';
import Modal from '../assets/img/logomodal.svg';
import eeuu from '../assets/img/eeuu.svg';
import '../assets/css/cambio.css';
import 'bootstrap/dist/js/bootstrap.min.js'
import Flechas from '../assets/img/flechas.svg'
import '../assets/css/home.css';
import Billete from '../assets/img/Billete.svg';
import opera from '../assets/img/opera.svg';
import flechamenor from '../assets/img/Flecha1inter.svg';
import Relogm from '../assets/img/imporpurple.svg';
import Reloga from '../assets/img/imporblue.svg';
import TIEMPO from '../assets/img/relojespera.svg';
import { Login } from "@mui/icons-material";
// import Modal from '../components/Modal/modal';
// import '../assets/scss/stepRegister.scss';
// import '../assets/css/StepIdentidad.css';

const Operation = (props) => {
    // context
    const [state,setState] = useContext(AppContext); 
    let history = useHistory();

    // const ENDPOINT = "http://64.227.12.24:4001";
    const [horarioAtencion, setHorarioAtencion] = React.useState(true);
    const [tipoCambioActual, setTipoCambioActual] = React.useState("");
    const [cambioConfirm, setCambioConfirm] = React.useState({});
    const [userProfile, setUserProfile] = React.useState({});
    const [cuentas, setCuentas] = React.useState([]);

    const [cuentasPropia, setCuentasPropia] = React.useState([]);
    const [errors, setErrors] = React.useState({});
    const [cuentasCotiza, setCuentasCotiza] = React.useState();
    const [filtercuentasCotiza, setFilterCuentasCotiza] = React.useState();
    const [codeRefer, setCodeRefer] = React.useState("");
    const [tieneCode, setTieneCode] = React.useState(false);
    const [cuentaOrigen, setCuentaOrigen] = React.useState({});
    const [cuentaDestino, setCuentaDestino] = React.useState({});
    const [, setCompleted] = useState(false);
    const inputMontoCambio = useRef(null);
    const [montoOrigen,setMontoOrigen]= React.useState(100)
    const [montoDestino,setMontoDestino]= React.useState(montoOrigen/tipoCambioActual.valorCompra)
    const [monedaOrigen,setMonedaOrigen]= React.useState(2)
    const [dataForm, setDataForm] = React.useState({
        idOperation: '',
        idCuentaOrigen: '***',
        idCuentaDestino: '***',
        montoCambio: '',
        idMonedaOrigen: '',
        idMonedaDestino: '',
        montoDestino:'',
        montoOrigen:''
    });
    const [numeroOperacion, setNumeroOperacion] = React.useState(0);
    
    const [bancos, setBancos] = React.useState([]);

    function cambiarDestino(val) {
        //console.warn(tipoCambioActual.valorCompra*val.target.value)
        setMontoDestino(val.target.value)
        setMontoOrigen((monedaOrigen===1 ? (val.target.value*tipoCambioActual.valorVenta) : (val.target.value/tipoCambioActual.valorCompra)).toFixed(2))
    }

    function cambiarOrigen(val) {
        setMontoOrigen(val.target.value)
        setMontoDestino(monedaOrigen===1 ? (val.target.value/tipoCambioActual.valorVenta).toFixed(2) : (tipoCambioActual.valorCompra*val.target.value).toFixed(2))
    }

    function cambioMoneda() {
        console.warn(tipoCambioActual.valorCompra)
         setMonedaOrigen(monedaOrigen===1 ? 2 : 1)
        // setMontoOrigen(montoOrigen)
         console.warn(monedaOrigen)
         setMontoDestino(monedaOrigen===2 ? (montoOrigen/tipoCambioActual.valorVenta).toFixed(2) : (tipoCambioActual.valorCompra*montoOrigen).toFixed(2))
         console.warn(montoDestino)
         setState({ ...state, monedaorigen_id:monedaOrigen===1 ? 2 : 1})
    }
    const iconPerson = <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 9.99 9.99">
    <circle class="cls-1-1" cx="5" cy="5" r="5"/><g>
    <path class="cls-2-1" d="M5,2.37c.57,0,1.08,.23,1.45,.6,.37,.37,.6,.88,.6,1.45s-.23,1.08-.6,1.45c-.37,.37-.88,.6-1.45,.6s-1.08-.23-1.45-.6c-.37-.37-.6-.88-.6-1.45s.23-1.08,.6-1.45c.37-.37,.88-.6,1.45-.6h0Zm1.13,.92c-.29-.29-.69-.47-1.13-.47s-.84,.18-1.13,.47c-.29,.29-.47,.69-.47,1.13s.18,.84,.47,1.13,.69,.47,1.13,.47,.84-.18,1.13-.47c.29-.29,.47-.69,.47-1.13s-.18-.84-.47-1.13Z"/>
    <path class="cls-2-1" d="M8.27,8.77c-.14,.12-.28,.23-.44,.34v-.06c0-.65-.22-1.26-.59-1.74-.38-.49-.92-.85-1.53-1.01l.11-.43c.72,.18,1.34,.6,1.78,1.17,.38,.49,.62,1.08,.67,1.73h0Zm-6.1,.34c-.15-.1-.3-.22-.44-.34,.05-.64,.3-1.24,.67-1.72,.44-.57,1.06-.99,1.77-1.17h.03l.11,.42h-.03c-.61,.17-1.15,.53-1.53,1.02-.37,.48-.59,1.08-.59,1.73v.06Z"/></g></svg>


const iconContact = <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg"  width="30" height="30"  viewBox="0 0 9.99 9.99">
    <path class="cls-1-1" d="M5,0A5,5,0,1,1,0,5,5,5,0,0,1,5,0Z"/>
    <path class="cls-2-1" d="M5,2.37A2,2,0,0,1,7.05,4.42,2.06,2.06,0,1,1,3.54,3,2.08,2.08,0,0,1,5,2.37Zm1.13.92A1.61,1.61,0,1,0,6.6,4.42,1.6,1.6,0,0,0,6.13,3.29Z"/>
    <path class="cls-2-1" d="M8.27,8.77a4.7,4.7,0,0,1-.44.34V9.05A2.82,2.82,0,0,0,5.7,6.31l.11-.43A3.3,3.3,0,0,1,8.27,8.77Zm-6.11.34a3.57,3.57,0,0,1-.43-.34A3.28,3.28,0,0,1,4.17,5.88h0l.11.43h0A2.83,2.83,0,0,0,2.16,9.05Z"/>
    <path class="cls-2-1" d="M3.11,3.68a.54.54,0,0,1,.22,0,2.23,2.23,0,0,0,0,1.42.65.65,0,0,1-.2,0,.72.72,0,0,1-.66-.75A.71.71,0,0,1,3.11,3.68Z"/>
    <path class="cls-2-1" d="M6.94,3.68a.57.57,0,0,0-.22,0,2.1,2.1,0,0,1,0,1.42.65.65,0,0,0,.2,0,.71.71,0,0,0,.66-.75A.7.7,0,0,0,6.94,3.68Z"/>
    <path class="cls-2-1" d="M2.94,5a.26.26,0,0,1,0-.36.25.25,0,0,1,.35,0,1.39,1.39,0,0,0,.54.42,2.44,2.44,0,0,0,1.22.09.25.25,0,0,1,.28.22.24.24,0,0,1-.22.28,2.81,2.81,0,0,1-1.47-.12A1.81,1.81,0,0,1,2.94,5Z"/>
    <path class="cls-2-1" d="M3.16,3.83a.25.25,0,0,1-.25.25.26.26,0,0,1-.25-.26,2.11,2.11,0,0,1,1.42-2,2.66,2.66,0,0,1,1-.15,2.56,2.56,0,0,1,1,.19A2.06,2.06,0,0,1,7.33,3.87a.25.25,0,0,1-.5,0,1.6,1.6,0,0,0-1-1.57A2.25,2.25,0,0,0,5,2.12a2.12,2.12,0,0,0-.79.13A1.62,1.62,0,0,0,3.16,3.83Z"/></svg>


const iconMoney =   <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" width="30" height="30"  viewBox="0 0 9.99 9.99">
    <path class="cls-1-1" d="M5,0A5,5,0,1,1,0,5,5,5,0,0,1,5,0Z"/>
    <path class="cls-2-1" d="M5,2.37A2,2,0,0,1,7.05,4.42,2.06,2.06,0,1,1,3.54,3,2.08,2.08,0,0,1,5,2.37Zm1.13.92A1.61,1.61,0,1,0,6.6,4.42,1.6,1.6,0,0,0,6.13,3.29Z"/>
    <path class="cls-2-1" d="M8.27,8.77a4.7,4.7,0,0,1-.44.34V9.05A2.82,2.82,0,0,0,5.7,6.31l.11-.43A3.3,3.3,0,0,1,8.27,8.77Zm-6.11.34a3.57,3.57,0,0,1-.43-.34A3.28,3.28,0,0,1,4.17,5.88h0l.11.43h0A2.83,2.83,0,0,0,2.16,9.05Z"/>
    <polygon class="cls-2-1" points="3.43 6.35 4.11 7.04 5.07 6.02 6.07 7.01 6.74 6.36 7.05 6.69 6.07 7.64 5.08 6.66 4.12 7.68 3.11 6.66 3.43 6.35"/>
    <polygon class="cls-2-1" points="4.63 6.84 4.99 7.46 5.21 7.46 5.5 6.86 5.9 7.05 5.5 7.91 4.73 7.9 4.24 7.07 4.63 6.84"/>
    <path class="cls-2-1" d="M5.1,7.68,4.7,10l-.45,0,.41-2.33Z"/>
    <path class="cls-2-1" d="M5.61,7.61,6,9.89,5.57,10l-.4-2.28Z"/> </svg>



    
    const [steps, setSteps] = useState([
        { key: 1, icon: iconPerson, label: 'Registra', isDone: true},
        { key: 2, icon: iconContact, label: 'Transfiere', isDone: false},
        { key: 3, icon: iconMoney, label: 'Verifica', isDone: false},
    
      ]);

      const [activeStep, setActiveStep] = useState(steps[0]);
      const handleNext = () => {

        const newErrors = validate(dataForm, activeStep.key);
        setErrors(newErrors);
  
        if (Object.keys(newErrors).length === 0) {
            if (steps[steps.length - 1].key === activeStep.key) {
                setCompleted(true);
                return;
            }
  
            setCompleted(false);
            const index = steps.findIndex(x => x.key === activeStep.key);
            setSteps(prevStep => prevStep.map(x => {
                if (x.key === activeStep.key) x.isDone = true;
                return x;
                }))
            setActiveStep(steps[index + 1]);
        }
        else{
          setCompleted(false);
          //console.log(errors)
        }
      }
  
      const handleBack = () => {
          const index = steps.findIndex(x => x.key === activeStep.key);
          if (index === 0) return;
  
          setSteps(prevStep => prevStep.map(x => {
          if (x.key === activeStep.key) x.isDone = false;
          return x;
          }))
          setActiveStep(steps[index - 1]);
      }
    
    const [views, setViews] = React.useState({
        calculate: true,
        operate: false,
        confirm: false,
        verificar: false,
        terminar: false,
        modal:false
      });

      const CambiarVista = (e, id) => {
        setViews({
            ...views,
            'calculate' : id === 1,
            'operate': id === 2,
            'confirm' : id === 3,
            'verificar' : id === 4,
            'terminar' : id === 5,
            'modal' : id === 6
        });
    }


    React.useEffect(() => {
        if (views.calculate) {
            // setTimeout(() => {
            //     RetornarTipoCambio(codeRefer);
            // },5000)
            
            
            RetornarTipoCambio(codeRefer);
        }

        const oSession = JSON.parse(localStorage.getItem("user_sesion"))
        setUserProfile(oSession);

        console.log('oSession',oSession);
        //20240131
        if (oSession == null){
            history.push('/admin/elegir-perfil');
            return;
        }
        if (oSession.complete === 0){
            history.push('/admin/terminar-persona');
            return;
        }

        
        
        if (oSession.namePerfil == null){
            history.push('/admin/elegir-perfil');
            window.location.reload();
            return;
        }

        // if (!oSession.namePerfil.trim()){
        //     history.push('/admin/elegir-perfil');
        //     window.location.reload();
        //     return;
        // }


        RetornarBanco();
        VerificarHorario();
        RetornarCuentasCotiza();
        RetornarCuenta(oSession.idProfile);
        setDataForm({ ...dataForm, idCuentaOrigen: '***', idCuentaDestino: '***', numeroOperacion: '', bancoCuentaNueva: '***', numeroCuentaNueva: '', aliasCuentaNueva: ''})
        setErrors({});
    }, [views]);    

    // Vista de componentes

    const VerificarHorario = async () => {
        const data = await TypeService.verifyHorario();
        setHorarioAtencion(data);
    }

    const RetornarBanco = async () => {
        const data = await TypeService.getBanco();
        setBancos(data);
    }

    //console.log('Tipo Cambio Socket');
    //console.log(tipoCambioActual);


    const [dateState, setDateState] = useState(new Date());
    useEffect(() => {
           setInterval(() => setDateState(new Date()), 30000);
    }, []);



    const RetornarCuentasCotiza = async () => {
        const data = await TypeService.returnCuentaCotiza();
        setFilterCuentasCotiza(data);
    }

    // const VerificarHorario = async () => {
    //     const data = await TypeService.verifyHorario();
    //     setHorarioAtencion(data.verificaHorario === "1");
    // }

    const RetornarTipoCambio = async (codigoPromo) => {
        await OperationService.returnTipoCambio(codigoPromo).then((response) => {
                setTipoCambioActual(response.data.tipocambio);
                //setMontoDestino((montoOrigen/response.data.tipocambio.valorCompra).toFixed(2));
                //cambioMoneda();
                setMontoDestino(monedaOrigen==1 ? (montoOrigen/response.data.tipocambio.valorVenta).toFixed(2) : (response.data.tipocambio.valorCompra*montoOrigen).toFixed(2))
                //console.log("retornar tipo cambio, moneda origen:", monedaOrigen)
            });
    }

    const RetornarCuenta = async (idProfile) => {
        const data = await OperationService.getAccountByProfile(idProfile);
        setCuentas(data);
    }

    const onChangeValue = (e) => {
        ////console.log(e.target.value);
        setDataForm({ ...dataForm, [e.target.name]: e.target.value })
        if (e.target.value == '****') {
            //history.push('/admin/editar-perfil');

        }
    }

    // const superaMontoMaximo = async () => {
    //     //consultar BD
    //     const data = await OperationService.montoSolicitadoPerfil(userProfile.idProfile);
    //     console.log(data,data.data[0].nmonto, Number(montoOrigen) + Number(data.data[0].nmonto),
    //     (monedaOrigen==1 && Number(montoDestino) + Number(data.data[0].nmonto) > horarioAtencion.montoMaximo) || (monedaOrigen!=1 && Number(montoOrigen) + Number(data.data[0].nmonto) > horarioAtencion.montoMaximo));
    //     //return true;
    //     return (monedaOrigen==1 && Number(montoDestino) + Number(data.data[0].nmonto) > horarioAtencion.montoMaximo) || (monedaOrigen!=1 && Number(montoOrigen) + Number(data.data[0].nmonto) > horarioAtencion.montoMaximo);
    //     // if((monedaOrigen==1 && Number(montoDestino) + Number(data.data[0].nmonto) > horarioAtencion.montoMaximo) || (monedaOrigen!=1 && Number(montoOrigen) + Number(data.data[0].nmonto) > horarioAtencion.montoMaximo)){
    //     //     return true;
    //     // }
    //     // else{
    //     //     return false;
    //     // }
    // }

    // const  superaMontoMaximo = async () => {
    //     return new Promise((resolve, reject) => {
    //         const data = await OperationService.montoSolicitadoPerfil(userProfile.idProfile)
    //         console.log(data,data.data[0].nmonto, Number(montoOrigen) + Number(data.data[0].nmonto),
    //         (monedaOrigen==1 && Number(montoDestino) + Number(data.data[0].nmonto) > horarioAtencion.montoMaximo) || (monedaOrigen!=1 && Number(montoOrigen) + Number(data.data[0].nmonto) > horarioAtencion.montoMaximo));
    //         //return true;
    //         if((monedaOrigen==1 && Number(montoDestino) + Number(data.data[0].nmonto) > horarioAtencion.montoMaximo) || (monedaOrigen!=1 && Number(montoOrigen) + Number(data.data[0].nmonto) > horarioAtencion.montoMaximo)){
    //             resolve(true);
    //         }
    //         else{
    //             resolve(false);
    //         }
    //     });
    // }

    const Cotizar = (e) => {
        //console.log("cotizar", horarioAtencion, horarioAtencion.verificaHorario)
        if (horarioAtencion.verificaHorario == 0) {
            alert("Se encuentra fuera de horario. Puedes realizar operaciones de L-V de 8:30 AM a 6:00 PM y Sábados de 8:30 AM a 1:00 PM. Muchas Gracias.");
            return
        }
        let errorsCotiza = {};
        if (!dataForm.montoDestino.trim()) {
            errorsCotiza.operation = "Seleccione Operación"
        }

        if (Number(dataForm.montoDestino.trim())) {
            errorsCotiza.operation = "Seleccione Operación"
        }

        if((monedaOrigen==1 && montoDestino > horarioAtencion.montoMaximo) || (monedaOrigen!=1 && montoOrigen > horarioAtencion.montoMaximo)){
            alert("Actualmente el monto máximo de operación es: $" + horarioAtencion.montoMaximo.toString());
            return
        }

        if((monedaOrigen==1 && montoDestino < horarioAtencion.montoMinimo) || (monedaOrigen!=1 && montoOrigen < horarioAtencion.montoMinimo)){
            alert("Actualmente el monto mínimo de operación es: $" + horarioAtencion.montoMinimo.toString());
            return
        }

        //validar máximo solicitado por usuario : $5,000
        //hacerlo en método
        //superaMontoMaximo().then((resultado) => console.log(resultado))
        
        // {
        //     if (resultado){
        //         alert("Ud cuenta con operaciones en proceso de atención, El monto máximo en proceso es: $" + horarioAtencion.montoMaximo.toString());
        //         return
        //     }
        // })
        
        // var myHeaders = new Headers();
        // myHeaders.append("Content-Type", "application/json");

        // var raw = JSON.stringify({
        // "email": "herbertvargas@msn.com"
        // });

        // var requestOptions = {
        // method: 'POST',
        // headers: myHeaders,
        // body: raw,
        // redirect: 'follow'
        // };

        // fetch("http://127.0.0.1:8000/api/montoSolicitadoPerfil", requestOptions)
        // .then(response => response.text())
        // .then(result => console.log(result, result[0]))
        // .catch(error => console.log('error', error));
        

        if
            (RetornarTipoCambio(codeRefer));

            setViews({
                ...views, 'calculate' : false, 'operate': true, 'confirm' : false, 'verificar' : false , 'terminar' : false , 'modal' : false
            });

            const origen = filtercuentasCotiza && filtercuentasCotiza.filter(x => x.moneda_id === monedaOrigen);
            setCuentasCotiza(origen);

            const tipoDestino = (monedaOrigen==1 ? 2 : 1);
            const destino = cuentas && cuentas.filter(x => x.moneda_id === tipoDestino)
            setCuentasPropia(destino);

    }

    const ValidarCuentas = (e) => {

        let errorsCuenta = {};

        if (horarioAtencion.verificaHorario == 0) {
            alert("Se encuentra fuera de horario. Puedes realizar operaciones de L-V de 8:30 AM a 6:00 PM y Sábados de 8:30 AM a 1:00 PM. Muchas Gracias.");
            return
        }
		
		if((monedaOrigen==1 && montoDestino > horarioAtencion.montoMaximo) || (monedaOrigen!=1 && montoOrigen > horarioAtencion.montoMaximo)){
            alert("Actualmente el monto máximo de operación es: $" + horarioAtencion.montoMaximo.toString());
            return
        }

        if((monedaOrigen==1 && montoDestino < horarioAtencion.montoMinimo) || (monedaOrigen!=1 && montoOrigen < horarioAtencion.montoMinimo)){
            alert("Actualmente el monto mínimo de operación es: $" + horarioAtencion.montoMinimo.toString());
            return
        }

        if (dataForm.idCuentaOrigen.trim() === '***') {
            errorsCuenta.bancoOrigen = "Seleccione Banco Origen"
        }

        if (dataForm.idCuentaDestino.trim() === '***') {
            errorsCuenta.bancoDestino = "Seleccione Banco Destino"
        }

        // ======================================
        // VALIDAR DATOS CUANDO ES NUEVA CUENTA
        // ======================================
        // console.log('dataForm', dataForm, dataForm.bancoCuentaNueva, dataForm.bancoCuentaNueva == null);
        // console.log('resultado', dataForm.idCuentaDestino.trim() === '****' && (dataForm.bancoCuentaNueva === '***' || dataForm.bancoCuentaNueva == null))
        // console.log('resultado2', dataForm.idCuentaDestino.trim() === '****' && (dataForm.numeroCuentaNueva == '' || dataForm.numeroCuentaNueva == null))
        // return;
        if (dataForm.idCuentaDestino.trim() === '****' && (dataForm.bancoCuentaNueva === '***' || dataForm.bancoCuentaNueva == null)) {
            errorsCuenta.bancoCuentaNueva = "Seleccione banco"
        }

        if (dataForm.idCuentaDestino.trim() === '****' && (dataForm.numeroCuentaNueva == '' || dataForm.numeroCuentaNueva == null)) {
            errorsCuenta.numeroCuentaNueva = "Ingrese cuenta a crear"
        }
        // ======================================
        // ======================================

        if (Object.keys(errorsCuenta).length !== 0){
            setErrors(errorsCuenta);
            return;
        }
        else {
            //console.log('Valores:');
            //console.log(dataForm);

            if (dataForm.idCuentaDestino.trim() === '****') {
                //alert('llamar a servicio');
                RegistrarNuevaCuenta();
                //return;
            }
            const dataOrigen = cuentasCotiza.filter(x => x.id === parseInt(dataForm.idCuentaOrigen)).shift();
            setCuentaOrigen(dataOrigen);

            //console.log('Datos Cotiza');
            //console.log(dataOrigen);

            const dataDestino = cuentasPropia.filter(x => x.cuenta_id === parseInt(dataForm.idCuentaDestino)).shift();
            setCuentaDestino(dataDestino);

            setCambioConfirm(tipoCambioActual);

            setDataForm({...dataForm, 'idMonedaOrigen': monedaOrigen == '1' ? '' : '', 'idMonedaDestino': monedaOrigen == '1' ? '' : '' });

            setViews({
                ...views, 'calculate' : false, 'operate': false, 'confirm' : true , 'verificar' : false , 'terminar' : false , 'modal' : false 
            });
        }
    }


    const RegistrarNuevaCuenta = async () => {
        Promise.all(
        [
            OperationService.createAccountBank(
                userProfile.idProfile,
                dataForm.bancoCuentaNueva,
                dataForm.numeroCuentaNueva,
                monedaOrigen == '1' ? '2' : '1',
                '1',
                dataForm.aliasCuentaNueva,
                1
            )
        ])
        .then(function (results) {
            setCuentaDestino(results[0]);
            //Setear la cuenta de operación 
            // const pen = results[0];
            // const usd = results[1];
            // console.log('results', results);
            // console.log('results[0]', results[0]);
            //debugger;
            
            // history.push('/admin/operar');
            // window.location.reload();
            //console.log('cuentaDestino',cuentaDestino);
        });
    }


    const Veridficar = (e) => {
        if (horarioAtencion.verificaHorario == 0) {
            alert("Se encuentra fuera de horario. Puedes realizar operaciones de L-V de 8:30 AM a 6:00 PM y Sábados de 8:30 AM a 1:00 PM. Muchas Gracias.");
            return
        }
		
		if((monedaOrigen==1 && montoDestino > horarioAtencion.montoMaximo) || (monedaOrigen!=1 && montoOrigen > horarioAtencion.montoMaximo)){
            alert("Actualmente el monto máximo de operación es: $" + horarioAtencion.montoMaximo.toString());
            return
        }

        if((monedaOrigen==1 && montoDestino < horarioAtencion.montoMinimo) || (monedaOrigen!=1 && montoOrigen < horarioAtencion.montoMinimo)){
            alert("Actualmente el monto mínimo de operación es: $" + horarioAtencion.montoMinimo.toString());
            return
        }

        setViews({
            ...views, 'calculate' : false, 'operate': false, 'confirm' : false , 'verificar' : true , 'terminar' : false , 'modal' : false
        });
    
    }

    const Terminar = (e) => {

        setViews({
            ...views, 'calculate' : false, 'operate': false, 'confirm' : false , 'verificar' : false , 'terminar' : true ,'modal' : false
        });
    
    }

    const modal = (e) => {

        setViews({
            ...views, 'calculate' : false, 'operate': false, 'confirm' : false , 'verificar' : false , 'terminar' : false ,'modal' : true
        });
    
    }


    const GrabarOperacion = (e) => {
        if (horarioAtencion.verificaHorario == 0) {
            alert("Se encuentra fuera de horario. Puedes realizar operaciones de L-V de 8:30 AM a 6:00 PM y Sábados de 8:30 AM a 1:00 PM. Muchas Gracias.");
            return
        }
		
		if((monedaOrigen==1 && montoDestino > horarioAtencion.montoMaximo) || (monedaOrigen!=1 && montoOrigen > horarioAtencion.montoMaximo)){
            alert("Actualmente el monto máximo de operación es: $" + horarioAtencion.montoMaximo.toString());
            return
        }

        if((monedaOrigen==1 && montoDestino < horarioAtencion.montoMinimo) || (monedaOrigen!=1 && montoOrigen < horarioAtencion.montoMinimo)){
            alert("Actualmente el monto mínimo de operación es: $" + horarioAtencion.montoMinimo.toString());
            return
        }
        
        if (dataForm.numeroOperacion == null || dataForm.numeroOperacion.length < 5) {
            setErrors({...errors, numeroOperacion:"Ingrese número de Operación"});
            return;
        }

        setViews({
            ...views, 'calculate' : false, 'operate': false, 'confirm' : false, 'verificar': false, 'terminar' : true , 'modal' : false
        });

        //console.log('Grabar Finalizar Operación', montoOrigen, monedaOrigen, dataForm);

        const fechaActual = new Date().toISOString().slice(0, 10);

        // //console.log(dataForm.idOperation === '1' ? dataForm.montoCambio :
        // parseFloat(dataForm.montoCambio * parseFloat(dataForm.idOperation === '1' ? cambioConfirm.valorCompra : cambioConfirm.valorVenta).toFixed(4)).toFixed(2));

        // //console.log(dataForm.idOperation === '2' ? dataForm.montoCambio :
        // parseFloat(dataForm.montoCambio * parseFloat(dataForm.idOperation === '2' ? cambioConfirm.valorCompra : cambioConfirm.valorVenta).toFixed(4)).toFixed(2));

        // //console.log(cambioConfirm);

        // console.log('cuentaDestino_al grabar',cuentaDestino);
        OperationService.registerSolicitud(
            userProfile.idUser,
            cuentaDestino.cuenta_id,
            cuentaOrigen.id,
            userProfile.idProfile,
            cambioConfirm.tipocambio_id,
            '1',
            montoOrigen,
            // dataForm.idOperation === '2' ? dataForm.montoCambio :
            //                                     parseFloat(dataForm.montoCambio * parseFloat(dataForm.idOperation === '1' ? 
            //                                     cambioConfirm.valorVenta : cambioConfirm.valorCompra).toFixed(4)).toFixed(2),
            fechaActual,
            dataForm.numeroOperacion,
            monedaOrigen == '1' ? '1' : '2',
            monedaOrigen == '1' ? '2' : '1'
        ).then((response) => {
            //console.log('datos final', response, response.data);
            // console.log('response.status', response.status);
            if (response.status != 201) {
                if (response.status == 200) {
                    alert('Monto Máximo solicitado por Usuario: $ 5,000.00. \nPuede ingresar otra operación cuando las operaiones en proceso hayan sido atendidas');
                    
                }
                else{
                    alert('Hubo un error al realizar la transacción, por favor inténtelo nuevamente');
                }
                setViews({
                    ...views, 'calculate' : true, 'operate': false, 'confirm' : false, 'verificar': false, 'terminar' : false , 'modal' : false
                });
            }
            else{
                setNumeroOperacion(response.data.solicitud_id)
                setViews({
                    ...views, 'calculate' : false, 'operate': false, 'confirm' : false, 'verificar': false, 'terminar' : false , 'modal' : true
                });
            }
            

        }).catch(function (error) {
            alert('Hubo un error al realizar la transacción, por favor inténtelo nuevamente');
            setViews({
                ...views, 'calculate' : true, 'operate': false, 'confirm' : false, 'verificar': false, 'terminar' : false , 'modal' : false
            });
        });
    }

    const [, setCopySuccess] = React.useState('');
    const [, setAlert] = React.useState(false);

    // function copyToClipBoard (copyMe) {
    //     var copia = copyMe.replaceAll(' ','');
    //     navigator.clipboard.writeText(copia);
    // };

    
    // const RetornarCuentasCotiza = async () => {
    //     const data = await TypeService.returnCuentaCotiza();
    //     setFilterCuentasCotiza(data);
    // }

    const  copyToClipBoard = async (copyMe) => {
        try {
            var copia = copyMe.replaceAll(' ','');
            await navigator.clipboard.writeText(copia);
        } catch (e) {
            //console.log(e);
        }
       }

    function accountdHide(account) {
        let hideNum = [];
          for(let i = 0; i < account.length; i++){
          if(i < account.length-4){
            hideNum.push("*");
          }else{
            hideNum.push(account[i]);
          }
        }
        return hideNum.join("");
      }



    return (

    <section  className='opera'>

            
        <div className='operaCambio'>

        { views.calculate &&
            <div className="p-42">
                <div className="col-md-33">
                    <div className='elementotitulo'>Bienvenido(a)

                    <span className='menuname'></span> {userProfile.namePerfil} <br/>
                         
                    
                    
                    </div>
                        <p className='elementop1'>¿Qué quieres hacer hoy?</p>

                     
                    <div className='p1'>
                            Recuerda que en 3 sencillos pasos puedes cambiar soles o dólares. Empecemos!!!
                    </div>
                    <img  src={opera} className="imgopera" alt="" />
                </div>
                <div class="contenedor-tipo-cambio">
                    <div className='contenedor-convertidor1'>
                        <div className="precio1">
                            <div className="texto1">COMPRA:</div>
                            <div className='textow'>{tipoCambioActual.valorCompra}</div>
                        </div>
                        <div class="vl-cv"></div>
                        <div className="precio2">
                            <div className='texto1'>VENTA:</div>
                            <div className='textow'>{tipoCambioActual.valorVenta}</div>
                        </div>
                    </div>
                    <hr className="hropera" />
                    <div class="conteniner" >
                        <div class="operacion">
                            {monedaOrigen==1 ? <img src={peru} alt=""  width="6%"/>: <img src={eeuu} alt=""  width="6%"/>}
                            <>&nbsp;</>Envías<>&nbsp;</>
                            {monedaOrigen==1 ? "soles" : "dólares"}
                            <div class="caja1-tipo-cambio">
                                <div className='cajapen'>
                                    {monedaOrigen==1 ? "PEN S/." : "USD $"}
                                </div>
                                <input type="number" class="imput-tipo-cambio1" value={montoOrigen} name="valini" id="valini" autoComplete="off" onChange={cambiarOrigen}/>
                            </div>
                        </div>
                        <div class="text-centerq" onClick={cambioMoneda}>
                            <img src={Flechas} className='fintercambio' alt="" /></div>
                            <div class="operacion">
                                {monedaOrigen==1 ? <img src={eeuu} alt=""  width="6%"/>: <img src={peru} alt=""  width="6%"/>}
                                <>&nbsp;</>Recibes<>&nbsp;</>
                                {monedaOrigen==1 ? "dólares" : "soles"}
                                <div class="caja1-tipo-cambio" >
                                    <div className='cajapen'>
                                        {monedaOrigen==1 ? "USD $ " : " PEN S/. "}
                                    </div>
                                        <input type="number" class="imput-tipo-cambio1" value={montoDestino} name="valfin" id="valfin" autoComplete="off" onChange={cambiarDestino} />
                                </div>
                            </div>
                            {/* <div className="form-check1">
                                    <input className="form-check-input"  type="checkbox" id="indicaCuenta" name="indicaCuenta"onClick={e => {setTieneCode(!tieneCode)}} />
                                    <label className="form-check-label1" htmlFor="defaultCheck1">Tienes código de descuento</label>
                            </div>
                            {tieneCode &&
                            <input type="text"  name="codeRefer" value={codeRefer} placeholder="Ingresa Código" className="form-control1 p-3 text-center" onChange={e => {setCodeRefer(e.target.value)}} />
                            } */}


                        <button type="button" className="mt-110" onClick={e => {Cotizar(e)}}>PROCESAR</button>
                    </div>
                </div>  
            </div>
        }
        </div> 
      
        <div className="" >
            { views.operate &&
                <div >
                    <div className="contenedorreloj1">
                        <div className="container">
                            <div className="wizard">
                                {/* INICIO MENU */}
                                <div className="steps clearfix">
                                    <ul className="nav moper">
                                        {steps.map((step, i) => {
                                            return    <li key={3} 
                                                
                                            className="first" 
                                            // class={`${activeStep.key === step.key ? 'current' : ''} `}
                                            class={`${views.operate && step.key==1 ? 'current' : ''} `}
                                            
                                            aria-disabled="false"
                                            aria-selected="true">
                                                <a >
                                                    <span className="current-info audible">current step: </span>
                                                    <div className="media">
                                                        <div className="bd-wizard-step-icon">
                                                            <i className="cls-1"></i>
                                                            {step.icon}
                                                        </div>
                                                        <div className="media-body0">
                                                            <div className="bd-wizard-step-subtitle pasos">Paso {i + 1}</div>
                                                            <div className="bd-wizard-step-title">{step.label}</div>                                            
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        })} </ul> 
                                </div>
                                {/* FIN MENU */}

                                {/* INICIO CONTENIDO */}
                                <div className="p-43">
                                    <div className="col-md-331">
                                        <div className='elementotitulo'>Registra tu operación</div>

                                        <div className="contreloj">Tiempo Restante:
                                        <Contador montoCambio={monedaOrigen == 1 ? tipoCambioActual.valorVenta : tipoCambioActual.valorCompra}
                                        setVista={setViews}
                                        /></div>

                                        <div class="conteniner" >
                                            <div className='contenedor-convertidor1'>
                                                <div className="precio1">
                                                    <div className="texto1">COMPRA:</div>
                                                    <div className='textow'>{tipoCambioActual.valorCompra}</div>
                                                </div>
                                                <div class="vl-cv"></div>
                                                <div className="precio2">
                                                    <div className='texto1'>VENTA:</div>
                                                    <div className='textow'>{tipoCambioActual.valorVenta}</div>
                                                </div>
                                            </div>
                                            <div class="operacion">
                                                {monedaOrigen==1 ? <img src={peru} alt=""  width="6%"/>: <img src={eeuu} alt=""  width="6%"/>}
                                                <>&nbsp;</>Envías<>&nbsp;</>
                                                {monedaOrigen==1 ? "soles" : "dólares"}
                                                <div class="caja1-tipo-cambio">
                                                    <div className='cajapen'>
                                                        {monedaOrigen==1 ? "PEN S/." : "USD $"}
                                                    </div>
                                                    <input type="number" class="imput-tipo-cambio1" value={montoOrigen} name="valini" id="valini" autoComplete="off" onChange={cambiarOrigen}/>
                                                </div>
                                            </div>
                                            <div class="text-centerq" >
                                                <img src={flechamenor} className='fintercambio' alt="" /></div>
                                                <div class="operacion cajamayor">
                                                    {monedaOrigen==1 ? <img src={eeuu} alt=""  width="6%"/>: <img src={peru} alt=""  width="6%"/>}
                                                    <>&nbsp;</>Recibes<>&nbsp;</>
                                                    {monedaOrigen==1 ? "dólares" : "soles"}
                                                    <div class="caja1-tipo-cambio" >
                                                        <div className='cajapen'>
                                                            {monedaOrigen==1 ? "USD $ " : " PEN S/. "}
                                                        </div>
                                                            <input type="number" class="imput-tipo-cambio1" value={montoDestino} name="valfin" id="valfin" autoComplete="off" onChange={cambiarDestino} />
                                                    </div>
                                                </div>
                                        </div>

                                        <img  src={opera} className="imgoperaP1" alt="" />
                                                
                                            
                                    
                                        <div id="horarioAtencion" hidden={horarioAtencion}>
                                            {/* <img src={imgClosed} alt=''/> <br/> */}
                                            <small className="text-white">Tipo Cambio</small>
                                            <h1>3.120</h1>
                                            <small className="text-white">Al cierre del Lunes</small>
                                            <p>
                                                Horario de Operaciones: <br/>
                                                Lunes a Viernes de 9:15am a 7:00pm <br/>
                                            </p>
                                        </div>
                                        
                                        <Loading />
                                    </div>
                                        
                                    {/* <img className='felchita' src={Flemayor} alt='' /> */}

                                    <div className="conti" >
                                        <label htmlFor="bancoOrigen" className="text-pur">Banco Origen:</label>
                                        <label className="text-negro">(desde dónde Ud.realiza el depósito)</label><br/>
                                        <select className="form-select" name="idCuentaOrigen" value={dataForm.idCuentaOrigen} onChange={e => {onChangeValue(e)}}>
                                            <option key="0" value="***">Seleccione</option>
                                            {cuentasCotiza && cuentasCotiza.map(item => {
                                                return (<option key={item.numerocuenta} value={item.id}>{item.nombreIFIS}</option>);
                                            })}
                                        </select>
                                        <small className="text-danger">{errors && errors.bancoOrigen}</small><br></br> 
                                        
                                        <label htmlFor="bancoDestino" className="text-pur">Banco Destino:</label>
                                        <label className="text-negro">(dónde Ud.recibirá el depósito)</label><br/>
                                        <select className="form-select" name="idCuentaDestino" value={dataForm.idCuentaDestino} onChange={e => {onChangeValue(e)}}>
                                            {/* <option key="0" value="***">Seleccionar</option> */}
                                            <option key="0" value="***" >Seleccione</option>
                                            {cuentasPropia && cuentasPropia.map(item => {
                                                return (<option key={item.numeroCuenta} value={item.cuenta_id}>{item.NombreBanco} - {item.alias} - {accountdHide(item.numeroCuenta)}</option>);
                                            })}
                                            <option key="0" value="****" >Agregar Cuenta</option>
                                                    
                                        </select>
                                        <small className="text-danger">{errors && errors.bancoDestino}</small>

                                        {/* =============================
                                            HABILITAR SI SE CREA CUENTA 
                                            =============================
                                        */}
                                        <div className="row mb-3" 
                                            style={{ display:(dataForm.idCuentaDestino == '****' ? 'block' : 'none')}}>
                                            <div className="col-md-9">
                                                <div className="form-group">
                                                    <label htmlFor="bancoCuentaNueva" className="text-muted">Banco Cuenta {monedaOrigen == '1' ? 'Dólares' : 'Soles'}:</label><br/>
                                                    <select className="form-select" name="bancoCuentaNueva" value={dataForm.bancoCuentaNueva} onChange={e => {onChangeValue(e)}}>
                                                        <option key="0" value="***">Seleccionar</option>
                                                        {bancos.map(item => {
                                                            return (<option key={item.ifi_id} value={item.ifi_id}>{item.nombreIFIS}</option>);
                                                        })}
                                                    </select>
                                                </div>
                                                <small className="text-danger">{errors && errors.bancoCuentaNueva}</small>
                                            </div>
            
                                            <div className="form-group col-md-9">
                                                <div className="form-group">
                                                    <label for="numeroCuentaNueva" className="text-muted">Número de Cuenta {monedaOrigen == '1' ? 'Dólares' : 'Soles'}:</label><br/>
                                                    <input type="number" name="numeroCuentaNueva" id="numeroCuentaNueva" className="form-control" defaultValue={dataForm.numeroCuentaNueva} onChange={e => {onChangeValue(e)}} />
                                                </div>
                                                <small className="text-danger">{errors && errors.numeroCuentaNueva}</small>
                                            </div>
            
                                            <div className="form-group col-md-9">
                                                <div className="form-group">
                                                    <label for="aliasCuentaNueva" className="text-muted">Alias de Cuenta:</label><br/>
                                                    <input type="text" name="aliasCuentaNueva" id="aliasCuentaNueva" className="form-control" defaultValue={dataForm.aliasCuentaNueva} onChange={e => {onChangeValue(e)}} />
                                                </div>
                                                <small className="text-danger">{errors && errors.aliasCuentaNueva}</small>
                                            </div>
                                        </div>
                                        
                                        
                                        {/* <div className="form-check1">
                                            <input className="form-check-input"  type="checkbox" id="indicaCuenta" name="indicaCuenta"onClick={e => {setTieneCode(!tieneCode)}} />
                                            <label className="form-check-label1" htmlFor="defaultCheck1">Tienes código de descuento</label>
                                        </div>
                                        {tieneCode &&
                                            <input type="text"  name="codeRefer" value={codeRefer} placeholder="Ingresa Código" className="form-control1 p-3 text-center" 
                                            onChange={e => {setCodeRefer(e.target.value)}} />
                                            
                                            }*/}
                                            
                                            <br></br>

                                        <div className="rowbotenes">
                                            <button type="button"  disabled={!horarioAtencion} className="btn mtd" onClick={e => {ValidarCuentas(e)}}>PROCESAR</button>
                                            <button type="button" className="btn btn-secondary " onClick={e => {CambiarVista(e, 1);}}>REGRESAR</button>
                                        </div> 
                                        
                                    </div>
                                    
                                </div> 
                                {/* FIN CONTENIDO */}
                            </div>
                        </div> 
                        {/* INICIO RELOJ  */}
                        <div className="contclock ">
                                    {/* <ClientSocket setTipoCambio={setTipoCambioActual} codePromo={codeRefer} /> */}
                                    {/* <h4 className='txt10'>Vendes</h4>
                                    <h4 className='txt11'>{monedaOrigen == 1? "S/." : "$ "} {montoOrigen}</h4>  */}
                                    <center>
                                    <img src={Reloga} className='' width={'20%'} />
                                    <div className='text-azul'>Para mantener el tipo de cambio acordado deberá realizar su operación
                                                            dentro de los siguientes 2 minutos</div>
                                                            
                                    <CountCircle
                                        montoCambio={monedaOrigen == 1 ? tipoCambioActual.valorVenta : tipoCambioActual.valorCompra}
                                        setVista={setViews}
                                    /><br/></center>
                            
                                    {/* <h4 className='txt10'>Te enviaremos</h4>
                                    <h4 className="txt11">{monedaOrigen == 1 ? "$ " : "S/."}{montoDestino}</h4> */}
                                    
                        </div>
                        {/* FIN RELOJ  */}
                    </div> 
                </div>
            }
        </div>

        <div className="">
            { views.confirm &&
                <div>
                    <div className="contenedorreloj1">
                        <div className="container">
                            <div className="wizard ">
                                {/* INICIO MENU */}
                                <div className="steps clearfix ">
                                    <ul className="nav moper">
                            {steps.map((step, i) => {
                                        return    <li key={3} role="tab" className="first" 
                                                        class={`${views.confirm && step.key == 2 ? 'current' : ''} `} 
                                                        aria-disabled="false" aria-selected="true">
                                                        <a id={`wizard-t-${step.key - 1}`} href={`#wizard-h-${step.key - 1}`} 
                                                        aria-controls={`wizard-p-${step.key - 1}`}>
                                                            <span className="current-info audible">current step: </span>
                                                            <div className="media">
                                                                <div className="bd-wizard-step-icon">
                                                                    <i className="cls-1"></i>
                                                                    {step.icon}
                                                                </div>
                                                                <div className="media-body0">
                                                                    <div className="bd-wizard-step-subtitle">Paso {i + 1}</div>
                                                                    <div className="bd-wizard-step-title">{step.label}</div>
                                                                    
                                                                </div>
                                                                
                                                            </div>
                                                        </a>
                                                    </li>
                                        })} </ul>
                                </div>
                                 {/* FIN MENU */}

                                 {/* INICIO CONTENIDO */}
                                <div className="p-43">
                                    <div className="col-md-331">
                                        <div className='elementotitulo'>Registra la transferencia</div>

                                        <div className="contreloj">Tiempo Restante:
                                        <Contador montoCambio={monedaOrigen == 1 ? tipoCambioActual.valorVenta : tipoCambioActual.valorCompra}
                                        setVista={setViews}
                                        /></div>
                                        <div class="conteniner" >
                                        <div className='contenedor-convertidor1'>
                                            <div className="precio1">
                                                <div className="texto1">COMPRA:</div>
                                                <div className='textow'>{tipoCambioActual.valorCompra}</div>
                                            </div>
                                            <div class="vl-cv"></div>
                                            <div className="precio2">
                                                <div className='texto1'>VENTA:</div>
                                                <div className='textow'>{tipoCambioActual.valorVenta}</div>
                                            </div>
                                        </div>
                                            <div class="operacion">
                                                {monedaOrigen==1 ? <img src={peru} alt=""  width="6%"/>: <img src={eeuu} alt=""  width="6%"/>}
                                                <>&nbsp;</>Envías<>&nbsp;</>
                                                {monedaOrigen==1 ? "soles" : "dólares"}
                                                <div class="caja1-tipo-cambio">
                                                    <div className='cajapen'>
                                                        {monedaOrigen==1 ? "PEN S/." : "USD $"}
                                                    </div>
                                                    <input type="number" class="imput-tipo-cambio1" value={montoOrigen} name="valini" id="valini" autoComplete="off" onChange={cambiarOrigen}/>
                                                </div>
                                            </div>
                                            <div class="text-centerq" >
                                                <img src={flechamenor} className='fintercambio' alt="" /></div>
                                                <div class="operacion cajamayor">
                                                    {monedaOrigen==1 ? <img src={eeuu} alt=""  width="6%"/>: <img src={peru} alt=""  width="6%"/>}
                                                    <>&nbsp;</>Recibes<>&nbsp;</>
                                                    {monedaOrigen==1 ? "dólares" : "soles"}
                                                    <div class="caja1-tipo-cambio" >
                                                        <div className='cajapen'>
                                                            {monedaOrigen==1 ? "USD $ " : " PEN S/. "}
                                                        </div>
                                                            <input type="number" class="imput-tipo-cambio1" value={montoDestino} name="valfin" id="valfin" autoComplete="off" onChange={cambiarDestino} />
                                                    </div>
                                                </div>
                                        </div>

                                        <img  src={opera} className="imgoperaP1" alt="" />
                                        <Loading />
                                    </div>

                                    <div className="conti">
                                            <div className="alert alert-success" role="alert">
                                                <div className="contconfirmacion"> <b className="txtrecibes"> &nbsp;&nbsp;Ahora Transfiere:</b> &nbsp;&nbsp;
                                                    <label className="txtopreinfo" > {monedaOrigen== 1? "S/." : "$ "}&nbsp;{ Number(montoOrigen).toFixed(2)}</label>
                                                    <img src={Billete} alt=""  width="25%"/><br></br> 
                                                    <label className="continfo12">a la cuenta de cotiza</label>

                                                </div>
                                                
                                                
                                                
                                                <div className="continfof">
                                                    
                                                    <br></br>
                                                        <div className="continfverificarp1">
                                                            <p className="textoper">Nombre de la cuenta:</p>&nbsp;
                                                            { cuentaOrigen.nombreCuenta }
                                                        </div>

                                                        <div className="continfverificarp4">
                                                            <p className="textoper">RUC:</p>&nbsp;
                                                            { cuentaOrigen.ruc }
                                                        </div>
                                                        
                                                        <div className="continfverificarp3">
                                                            <p className="textoper">Banco:</p>&nbsp;
                                                            {  cuentaOrigen.nombreIFIS }
                                                        </div>

                                                        <div className="continfverificarp2">
                                                            <p className="textoper">Moneda:</p>&nbsp;
                                                            {  monedaOrigen==1 ? 'SOLES' : 'DÓLARES'}
                                                        </div>
                                                    
                                                </div>


                                            <hr className="linea1"/>
                                            
                                            <p className="textoper1">Numero de cuenta:</p>
                                                        <p className="textnc" > <b>{ cuentaOrigen.numerocuenta }</b> &nbsp;&nbsp;&nbsp;
                                                        <button>
                                                            <span onClick={() => copyToClipBoard(cuentaOrigen.numerocuenta)}><HiOutlineClipboardCopy /> </span> 
                                                            </button>
                                                            </p>
                                                        
                                                        </div>


                                                    <div className="contcconfirmar">
                                                        <p className="textoper" >Recibes:</p>&nbsp;&nbsp;
                                                        <b className="txtrecibes1">{monedaOrigen==1 ? "$ " : "S/."} {montoDestino}</b>
                                                        </div>

                                                        <div className="contcconfirmar">
                                                        <p className="textoper">Tipo de operación:</p>&nbsp;{monedaOrigen== 1? "Compra de Dólares" : "Venta de Dólares"}</div>



                                                    <div className="botonesconfirmar">
                                                    <button type="button" style={{backgroundColor: '#052AAC', border: 'none'}} className="btn mtd operbntconfirmar" onClick={e => {Veridficar(e)}}>Ya realicé la transferencia</button>
                                                    <button type="button" className="btn btn-secondary" onClick={e => {CambiarVista(e, 1)}}>Cancelar</button>
                                                    </div>
                                    </div>

                                </div>
                                 {/* FIN CONTENIDO */}
                            </div>
                        </div>   
                         {/* INICIO RELOJ */}
                        <div className="contclock ">
                                        {/* <ClientSocket setTipoCambio={setTipoCambioActual} codePromo={codeRefer} /> */}
                                        {/* <h4 className='txt10'>Vendes</h4>
                                        <h4 className='txt11'>{monedaOrigen == 1? "S/." : "$ "} {montoOrigen}</h4>  */}
                                        <center>
                                        <img src={Reloga} className='' width={'20%'} />
                                        <div className='text-azul'>Para mantener el tipo de cambio acordado deberá realizar su Operación
                                                                dentro de los siguientes 2 minutos</div>
                                                                
                                        <CountCircle
                                            montoCambio={monedaOrigen == 1 ? tipoCambioActual.valorVenta : tipoCambioActual.valorCompra}
                                            setVista={setViews}
                                        /><br/></center>
                                
                                        {/* <h4 className='txt10'>Te enviaremos</h4>
                                        <h4 className="txt11">{monedaOrigen == 1 ? "$ " : "S/."}{montoDestino}</h4> */}
                                        
                        </div>
                         {/* FIN RELOJ */}
                    </div> 
                </div>
            }

        </div> 
    
        <div className="">
        { views.verificar &&
        <div>
            <div className="contenedorreloj1">
                <div className="container">
                    <div className="wizard ">
                        {/* INICIO MENU */}
                        <div className="steps clearfix ">
                            <ul className="nav moper">
                    {steps.map((step, i) => {
                                return    <li key={3} role="tab" className="first" 
                                                class={`${views.verificar && step.key == 3 ? 'current' : ''} `} 
                                                aria-disabled="false" aria-selected="true">
                                                <a id={`wizard-t-${step.key - 1}`} href={`#wizard-h-${step.key - 1}`} 
                                                aria-controls={`wizard-p-${step.key - 1}`}>
                                                    <span className="current-info audible">current step: </span>
                                                    <div className="media">
                                                        <div className="bd-wizard-step-icon">
                                                            <i className="cls-1"></i>
                                                            {step.icon}
                                                        </div>
                                                        <div className="media-body0">
                                                            <div className="bd-wizard-step-subtitle">Paso {i + 1}</div>
                                                            <div className="bd-wizard-step-title">{step.label}</div>
                                                            
                                                        </div>
                                                        
                                                    </div>
                                                </a>
                                            </li>
                                })} </ul> 
                        </div>
                        {/* FIN MENU */}

                         {/* INICIO CONTENIDO */}
                        <div className="p-43">
                            <div className="col-md-331">
                                <div className='elementotitulo'>Verifica la transferencia</div>
                                
                                <div className="contreloj">Tiempo Restante:<Contador/></div>
                                <div class="conteniner" >
                                    <div className='contenedor-convertidor1'>
                                        <div className="precio1">
                                            <div className="texto1">COMPRA:</div>
                                            <div className='textow'>{tipoCambioActual.valorCompra}</div>
                                        </div>
                                        <div class="vl-cv"></div>
                                        <div className="precio2">
                                            <div className='texto1'>VENTA:</div>
                                            <div className='textow'>{tipoCambioActual.valorVenta}</div>
                                        </div>
                                    </div>
                                    <div class="operacion">
                                        {monedaOrigen==1 ? <img src={peru} alt=""  width="6%"/>: <img src={eeuu} alt=""  width="6%"/>}
                                        <>&nbsp;</>Envías<>&nbsp;</>
                                        {monedaOrigen==1 ? "soles" : "dólares"}
                                        <div class="caja1-tipo-cambio">
                                            <div className='cajapen'>
                                                {monedaOrigen==1 ? "PEN S/." : "USD $"}
                                            </div>
                                            <input type="number" class="imput-tipo-cambio1" value={montoOrigen} name="valini" id="valini" autoComplete="off" onChange={cambiarOrigen}/>
                                        </div>
                                    </div>
                                    <div class="text-centerq" >
                                        <img src={flechamenor} className='fintercambio' alt="" />
                                    </div>
                                    <div class="operacion cajamayor">
                                        {monedaOrigen==1 ? <img src={eeuu} alt=""  width="6%"/>: <img src={peru} alt=""  width="6%"/>}
                                        <>&nbsp;</>Recibes<>&nbsp;</>
                                        {monedaOrigen==1 ? "dólares" : "soles"}
                                        <div class="caja1-tipo-cambio" >
                                            <div className='cajapen'>
                                                {monedaOrigen==1 ? "USD $ " : " PEN S/. "}
                                            </div>
                                                <input type="number" class="imput-tipo-cambio1" value={montoDestino} name="valfin" id="valfin" autoComplete="off" onChange={cambiarDestino} />
                                        </div>
                                    </div>
                                </div>


                                <img  src={opera} className="imgoperaP1" alt="" />
                                <div id="horarioAtencion" hidden={horarioAtencion}>
                                    {/* <img src={imgClosed} alt=''/> <br/> */}
                                    <small className="text-white">Tipo Cambio</small>
                                    <h1>3.120</h1>
                                    <small className="text-white">Al cierre del Lunes</small>
                                    <p>
                                        Horario de Operaciones: <br/>
                                        Lunes a Viernes de 9:15am a 7:00pm <br/>
                                    </p>
                                </div>
                                <Loading />
                            </div>    
                    
                            <div className="conti" >

                              
                                <label className="ndotxt1">Ingresa el número de operación</label>
                                <p className="ndotxt2">que corresponde a esta transferencia. Este número te lo otorga tu banco.</p>
                                <div class="caja1-tipo-cambio">

                                    <input  class="imput-tipo-cambio1"  name="numeroOperacion" value={dataForm.numeroOperacion} 
                                            onChange={e => {onChangeValue(e)}} />
                                            <small className="text-danger">{errors && errors.numeroOperacion}</small>
                                            <br></br>
                                </div>

                                
                                    {/* <small className="text-danger">{errors && errors.bancoOrigen}</small><br></br>  */}
                                    
                                    
                                    <br></br>

                                <div className="botonesconfirmar">
                                <button type="button" style={{backgroundColor: '#052AAC', border: 'none'}} className="btn mtd" onClick={e => {GrabarOperacion()}}>FINALIZAR</button>
                                
                                    <button type="button" className="btn btn-secondary " onClick={e => {CambiarVista(e, 1);}}>CANCELAR</button>
                                </div>
                            </div>
                            </div>
                        {/* FIN CONTENIDO */}
                    </div> 
                </div>  
                  {/* INICIO RELOJ  */}
                  <div className="contclock ">
                                    {/* <ClientSocket setTipoCambio={setTipoCambioActual} codePromo={codeRefer} /> */}
                                    {/* <h4 className='txt10'>Vendes</h4>
                                    <h4 className='txt11'>{monedaOrigen == 1? "S/." : "$ "} {montoOrigen}</h4>  */}
                                    <center>
                                    <img src={Relogm} className='' width={'20%'} />
                                    <div className='text-morado'>
                                        <b>TE AYUDAMOS</b><br></br>
                                        Obtendras el <b>número de operación</b>  al transferirnos el monto desde la 
                                        aplicación o el portal web de tu banco</div>
                                                            
                                    <CountCircle
                                        montoCambio={monedaOrigen == 1 ? tipoCambioActual.valorVenta : tipoCambioActual.valorCompra}
                                        setVista={setViews }
                                    /><br/></center>
                            
                                    {/* <h4 className='txt10'>Te enviaremos</h4>
                                    <h4 className="txt11">{monedaOrigen == 1 ? "$ " : "S/."}{montoDestino}</h4> */}
                                    
                  </div>
                        {/* FIN RELOJ  */}        
            </div>
        </div>
                          
        }

        </div> 

        {/* <div className="">
        { views.terminar &&
        <div>
            <div className="contenedorreloj1">
                <div className="container">
                    <div className="wizard ">
                     

                        //  {/* INICIO CONTENIDO 
                        <div className="p-43">
                            <div className="col-md-331">
                                <div className='elementotitulo'>Estamos verificando la transferencia</div>

                                <div className='p1'>
                                    Esto nos tomará algunos minutos. Agradecemos tu paciencia
                                </div>  
                            
                                <img  src={opera} className="imgoperaP1" alt="" />
                                <div id="horarioAtencion" hidden={horarioAtencion}>
                                    {/* <img src={imgClosed} alt=''/> <br/> 
                                    <small className="text-white">Tipo Cambio</small>
                                    <h1>3.120</h1>
                                    <small className="text-white">Al cierre del Lunes</small>
                                    <p>
                                        Horario de Operaciones: <br/>
                                        Lunes a Viernes de 9:15am a 7:00pm <br/>
                                    </p>
                                </div>
                                
                            </div>

                             <div className="conti">
                                <div className="alert alert-success" role="alert">
                                    <div className="contconfirmacion1">
                               
                                    Tu número de operacion Cotiza es: #######
                                    </div>
                                    
                                    <center>
                                        <br></br>
                                        
                                        <div className="continfverificarp5">
                                            <p className="textoper">Vendiste:</p>&nbsp;
                                            <p className="txtterm1">{monedaOrigen==1 ? "PEN S/" : "USD $"}</p>
                                            &nbsp;<b className="textterminar">{ Number(montoOrigen).toFixed(2)}</b>
                                        </div>

                                        <div className="continfverificarp5">
                                            <p className="textoper">Recibiste:</p>&nbsp;
                                            <p lassName="txtterm1">{monedaOrigen==1 ? "USD $ " : " PEN S/"}</p> &nbsp;
                                            <b className="textterminar"> {montoDestino}</b>
                                        </div>
                                        
                                        <div className="continfverificarp5">
                                            <p className="textoper">Cuenta origen:</p>&nbsp;
                                            {dataForm.idCuentaOrigen}
                                        </div>

                                        <div className="continfverificarp5">
                                            <p className="textoper">Cuenta destino:</p>&nbsp;
                                            {dataForm.idCuentaDestino}
                                        </div>

                                        <div className="continfverificarp6">
                                            <p className="textoper">Cuenta de Cotiza:</p>&nbsp;
                                                <p className="textnc1" > { cuentaOrigen.numerocuenta } <span onClick={() => copyToClipBoard(cuentaOrigen.numerocuenta)}><HiOutlineClipboardCopy /> </span> </p>
                        
                                        </div>
                                            
                                    </center>
                                </div>


                                <div className="botonesconfirmar">
                                    <button type="button" className="mt-110"  onClick={e => {modal(e)}}>Terminar</button>
                                    {/* <button type="button" className="btn btn-secondary" onClick={e => {CambiarVista(e, 1)}}>Cancelar</button> 
                                </div>

                            </div>    
                    
                        </div>
                        {/* FIN CONTENIDO 

                      
                    </div> 
                </div>  
                  {/* INICIO RELOJ 
                  <div className="contclock ">
               
                    
                    <div className='text-contblue'>
                        <b>RECUERDA QUE</b><br></br>
                        enviaremos la confirmacion a tu CORREO</div>
                    
                        <div className="contrelogs">
                        <img src={TIEMPO} className='tiemporelog' />

                       <p className="textrelog">Hora aproximada para que recibas tu dinero:</p>  <br></br>
                 
                        </div>
                        <p className="textrelog2">
                            {dateState.toLocaleString('en-US', {
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true,
                            })}
                        </p>             
                 
                </div>
                  {/* FIN RELOJ      

            </div>
        </div>
                                        // <Modal 
                                        //     estado={estadoModal1}
                                        //     cambiarestado={cambiarestadoModal1}
        }

        </div>  */}


        
        <div className="">
        { views.terminar &&
        <div>
            <div className="contenedorreloj1">
                <div className="container">
                    <div className="wizard ">
                      
                    </div> 
                </div>  
                  
                  <div className="contclock ">
               
                    
                    <div className='text-contblue'>
                        <b>RECUERDA QUE</b><br></br>
                        enviaremos la confirmacion a tu CORREO</div>
                    
                        <div className="contrelogs">
                        <img src={TIEMPO} className='tiemporelog' />

                       <p className="textrelog">Hora aproximada para que recibas tu dinero:</p>  <br></br>
                 
                        </div>
                        <p className="textrelog2">
                            {dateState.toLocaleString('en-US', {
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true,
                            })}
                        </p>   
                </div>
            </div>
            <Loading />
        </div>
        }

        </div> 





        





        <div className=''>

            { views.modal &&
                <div className="contenedormodal1">
                    <p className="txtmo1">Gracias
                    <span className='menuname'></span> {userProfile.namePerfil} </p><br/>
                    <p className='txtmotitulo'>Realizamos la transferencia</p>
                    <p className="txtmo2">Correspondiente a la operación N° {numeroOperacion}</p> <br></br>

                    <div className="txtm2">
                        <div className="txtm21">
                            <p className="txt-t1">Tranfieres:</p><br></br>
                            <p className="txt-t2">{monedaOrigen== 1? "S/." : "$ "}{ Number(montoOrigen).toFixed(2)}</p> 
                        </div>
                        <div class="vl-cvmodal"></div>
                        <div className="txtm22">
                            <p className="txt-t1">Recibes:</p><br></br>
                            <p className="txt-t3">{monedaOrigen==1 ? "$ " : "S/."} {montoDestino}</p>
                        </div>


                    </div>
                  
                    <img src={Modal} className='' width={'10%'} />
                    <br></br>  <br></br>
                    <p className="txtmo3">Enviamos la información detallada a tu correo</p>


                    <div className="botonesconfirmar1 btnplomo">
                        <button type="button" className="btn btn-secondary" onClick={e => {CambiarVista(e, 1)}}>Volver al Inicio</button>
                        {/* <button type="button" className="btn btn-secondary" to={'/admin/ver-historial'} >Mis operaciones</button> */}
                    </div>
                    
                </div>
                
            }
            <Loading />
        </div> 

             
    </section>
    )
}

export default Operation