import React from "react";

import logo from '../../assets/img/logofooter.svg';
import app from '../../assets/img/googleplay.svg';
import '../../assets/css/footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { library } from '@fortawesome/fontawesome-svg-core' //core
// import { faLaptop } from "@fortawesome/free-solid-svg-icons";
import { faYoutube,faFacebookSquare } from '@fortawesome/free-brands-svg-icons'






const Footer = () => {
  
    return (
        <div id="footer" className="f-contenedor">
            <div class="container-fluid">
                <nav class="footer-contendor">


                <ul class="fcont0">
                <a title="" to="/" target="_blank">
                        <img src= {logo}  className="imgfooter" /></a>
                    </ul>
                    
                    <ul class="fcont1">
                        <li class="ftext1">CORPORACION COTIZA PE SAC<br></br> Ruc: 20611468181</li>
                        <li class="ftext1-1">Con autorizacion: <br></br> de la SBS
                        <br></br> N° 04993 - 2018</li>
                    </ul>
                    <div class="vl-footer"></div>
                    <ul class="fcont2">
                        <li class="ftext2">Horarios <br></br> de Atención</li>
                        <li class="ftext1-1">LUN - VIE<br></br>8:30am - 6:00pm<br></br>
                        
                        SAB<br></br>8:30am - 1:00pm</li>
                        
                    </ul>
                    <div class="vl-footer"></div>
              
                    <ul class="fcont3">
                        <li class="ftext2 ">Ayuda</li>
                        {/* <li class="ftext1"><a href="" class="text-reset">Atención al cliente</a></li> */}
                        <li class="ftext1-2"><a href="/Faq" class="text-reset">Preguntas Frecuentes</a></li>
                        <li class="ftext1-2"><a href="/Nosotros" class="text-reset">Nosotros</a></li>
                        {/* <li class="ftext1"><a  class="text-reset">Promociones</a></li> */}
                        
                    </ul>
                    <div class="vl-footer"></div>
                    <ul class="fcont4">
                        <li class="ftext2">Legal</li>
                
                        <li class="ftext1-2"><a href="/terminosycondiciones" class="text-reset">Términos y condiciones</a></li>
                        <li class="ftext1-2"><a  href="/politicas" class="text-reset">Políticas de privacidad</a></li>
                        <li class="ftext1-2"><a  class="text-reset">Libro de reclamaciones</a></li>
                        
                    </ul>
                    <div class="vl-footer"></div>
                    <ul class="fcont5">  

                    <a title="" href="https://play.google.com/store/apps/details?id=appmovil.cotiza&hl=es" target="_blank">
                        <img src= {app}  className="imgfooterapp" /></a>


                        <li class="ftext3">Siguenos en:
                            
                            <a href="https://www.facebook.com/Cotiza-pe-105372994960292" class="text-reset w-25f">
                                <i><FontAwesomeIcon  style={{fontSize: '22px'} } icon={faFacebookSquare} className="facebook1" /></i>
                            </a>
                            <a href="" class="text-reset w-25f ">
                                <i><FontAwesomeIcon  style={{fontSize: '22px'}} icon={faYoutube}  className="facebook"  /></i>
                            </a>
                        </li>
                    </ul>
                    
                </nav>
                {/* <div class="vl-cv-footer"></div> */}
            </div>
        </div>
    );
}

export default Footer;