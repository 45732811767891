import React, { useState } from "react";
import Footer from '../components/footer/Footer';
import Navbar from '../components/navbar/NavBar';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import SignInUp from '../views/SignInUp';
import ManageProfile from '../views/ManageProfile';
import RegisterPersonView from '../components/stepForm/Form';
import HistoryView from '../views/History';
import EditProfileView from '../views/Profile';
import OperationView from '../views/Operation';
import UserReferView from '../views/UserRefer';
import Faq from '../views/Faq';
import TerminosyCondiciones from '../views/TerminosyCondiciones';
import Politicas from '../views/Politicas';
import RegisterEmpresaView from '../views/RegisterEmpresa';
import NotFoundView from '../views/NotFound';
import HomeView from '../views/Home';
import KnowingView from '../views/Knowing';
import '../assets/css/base.css';
import PrivateRoute from '../components/helpers/PrivateRoute';
import Nosotros from '../views/Nosotros';





import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

const BaseTemplate = (props) => {
  
    const [ ] = useState(false);
    const [user, ] = useState({
        complete: false,
        token: '',
        id: '',
        name: '',
        email: '',
        phone: '',
        cellphone: '',
        profiles: []
    });

    return (     
        <div id="wrapper">
            <Router basename="/">
                <Navbar isShowLogin={user.complete} />
                <div id="page" className="col-md-12 col-lg-12">
                    <Switch> 
                        <Route exact path="/conocenos" component={KnowingView}/>
                        <Route exact path="/faq" component={Faq}/>
                        <Route exact path="/nosotros" component={Nosotros}/>
                        <Route exact path="/terminosycondiciones" component={TerminosyCondiciones}/>
                        <Route exact path="/politicas" component={Politicas}/>
                        <Route exact path="/blog" component={ManageProfile}/>
                        <Route exact path="/demo" component={EditProfileView}/>
                        <Route exact path="/afiliado" component={UserReferView}/> 

                        <PrivateRoute path="/admin/elegir-perfil" component={ManageProfile}/>
                        <PrivateRoute path="/admin/operar" component={OperationView} /> 
                        <PrivateRoute path="/admin/editar-perfil" component={EditProfileView}/> 
                        <PrivateRoute path="/admin/ver-historial" component={HistoryView}/>

                        <PrivateRoute path="/admin/terminar-persona" component={RegisterPersonView}/>
                        <PrivateRoute path="/admin/terminar-empresa" component={RegisterEmpresaView}/>
                        <Route exact path="/login"><SignInUp user={user}/></Route>
                    
                        <Route exact path="/login/reset-password/id=:id?||token=:token?"><SignInUp user={user} /></Route>
                        <Route exact path="/login/registro/reg=:reg?"><SignInUp user={user} /></Route>
                        <Route exact path="/" component={HomeView} />
                        <Route exact path="*" component={NotFoundView} />
                    </Switch>
                </div>

            </Router>

            <Footer />
            
            <a href="https://api.whatsapp.com/send?phone=51922367582&text=Hola CotizaPE quiero cotizar un cambio de moneda ..." class="btn-wsp" target="_blank">
                <i class="icon-whatsapp"><FontAwesomeIcon icon={faWhatsapp} /></i>
            </a>
        </div>   
    );
}

 

export default BaseTemplate;